import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header>
      <h1>
        <svg
          width="34"
          height="45"
          viewBox="0 0 34 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.79725 35.0902C11.1968 33.5898 12.5467 35.7114 12.5467 35.7114L10.1119 10.161C10.1477 9.64364 11.6826 8.94618 18.8563 5.57908C26.03 2.21197 29.2258 0.803046 29.9146 1.14814C30.7914 9.08138 32.5856 26.6263 32.847 28.3109C33.1737 30.4165 31.3458 31.6855 29.5946 32.5633C26.7276 34.0005 21.9462 33.312 20.8149 31.5444C20.0408 30.335 19.7851 27.7421 22.2542 25.4687C24.2296 23.6499 28.4028 24.3914 30.2426 24.9894L28.8771 10.5809L13.1932 18.0908L15.1436 40.5789C14.8826 41.511 12.039 43.9531 7.32853 43.9458C4.44353 43.9414 2.82329 42.3485 2.36228 41.3457C2.00745 39.8857 2.3977 36.5907 6.79725 35.0902Z"
            fill="white"
          />
          <path
            d="M15.1436 40.5789C14.8826 41.511 12.039 43.9531 7.32853 43.9458C4.44353 43.9414 2.82329 42.3485 2.36228 41.3457C2.00745 39.8857 2.3977 36.5907 6.79725 35.0902C11.1968 33.5898 12.5467 35.7114 12.5467 35.7114L10.1119 10.161C10.1477 9.64364 11.6826 8.94618 18.8563 5.57908C26.03 2.21197 29.2258 0.803046 29.9146 1.14814C30.7914 9.08138 32.5856 26.6263 32.847 28.3109C33.1737 30.4165 31.3458 31.6855 29.5946 32.5633C26.7276 34.0005 21.9462 33.312 20.8149 31.5444C20.0408 30.335 19.7851 27.7421 22.2542 25.4687C24.2296 23.6499 28.4028 24.3914 30.2426 24.9894L28.8771 10.5809L13.1932 18.0908L15.1436 40.5789ZM15.1436 40.5789C15.3912 39.6946 14.4477 43.0645 15.1436 40.5789Z"
            stroke="white"
          />
        </svg>
        Melody Quest
      </h1>
      <p>Guess the song challenge</p>
    </header>
  );
};

export default Header;
